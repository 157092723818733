"use client";

import React, { useState, useTransition } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import type { SignInSchema } from "@gsa/db/schema";
import { authenticate } from "@gsa/auth/actions";
import { signInSchema } from "@gsa/db/schema";
import { LoadingButton } from "@gsa/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@gsa/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@gsa/ui/components/ui/form";
import { AlertCircleIcon, ChevronRight } from "@gsa/ui/icons";
import { Input } from "@gsa/ui/input";

export default function LoginForm() {
  const router = useRouter();

  const [pending, startTransaction] = useTransition();
  const [error, setError] = useState<string | undefined>();

  const form = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),

    defaultValues: {},
  });

  const onSubmit = async (input: SignInSchema) => {
    startTransaction(async () => {
      const result = await authenticate(input);

      if (result.ok === false) {
        setError(result.message);
      } else {
        setError("");
        window.location.href = "/dashboard";
      }

      // if (result?.ok === false) {
      //   setError(result?.message ?? "Failed to sign up");
      // } else {
      //   setError("");
      //   router.push("/dashboard/account/profile");
      // }
    });
  };

  return (
    <Form {...form}>
      <div className=" mx-auto w-full md:max-w-md lg:py-32">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="relative grid gap-8  pb-4"
        >
          <Card className="border-0 shadow-none ">
            <CardHeader className="space-y-1 text-center">
              <CardTitle className="text-2xl">Login</CardTitle>
              <CardDescription>
                Enter your email & password below to login
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4 ">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Email Address <span className="text-red-600">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value ?? ""}
                        type="email"
                        autoComplete="email"
                        required
                        className="w-full"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Password <span className="text-red-600">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value ?? ""}
                        type="password"
                        required
                        autoComplete="new-password"
                        className="w-full"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {error && (
                <div
                  className="flex h-8 items-end space-x-1"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <AlertCircleIcon className="h-5 w-5 text-red-500" />
                  <p className="text-sm text-red-500">{error}</p>
                </div>
              )}
            </CardContent>

            <CardFooter>
              <LoadingButton
                loading={pending}
                disabled={pending}
                className="w-full justify-between"
              >
                Login <ChevronRight />
              </LoadingButton>
            </CardFooter>
          </Card>
        </form>

        <div className="flex flex-col gap-1 px-4 text-center text-sm text-muted-foreground">
          <div>
            Forgot your password?{" "}
            <Link href="/account/forgot-password">
              <span className="text-green-500">Reset it here</span>
            </Link>
          </div>
        </div>
      </div>
    </Form>
  );
}
