
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"09d8993f4985c606dc409b0a3f7c71cc6357bd2d":"signUp","3e33488e36d708fabd96b3a8d9aa25844b7f1fbf":"$$ACTION_0","840021a0948cf3114ba8fd251756fee8d4c809ac":"refreshSession","b769977de6ee3e0fef3bccf91283a3d5bfefc64c":"forgotPassword","cbc42b5152fe576c93cc007ce88013f43aa3cc3e":"signOut","e23cac937a8d66e3479c28c68ee5ec5663c7e068":"updateUser","e4b7ae9082d58dec8f24bac9ea993cfcf781263d":"resetPassword","e9d2ca670b80aab220634b9ab4a796b44765c9d1":"authenticate","ed38acc4fc053fa7662719642bd6e83960bca30b":"changePassword","fbeddb37733a962c3dc7c576c686e3722a84d718":"initAuthActions"} */ export var updateUser = createServerReference("e23cac937a8d66e3479c28c68ee5ec5663c7e068");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var initAuthActions = createServerReference("fbeddb37733a962c3dc7c576c686e3722a84d718");
export var refreshSession = createServerReference("840021a0948cf3114ba8fd251756fee8d4c809ac");
export var signUp = createServerReference("09d8993f4985c606dc409b0a3f7c71cc6357bd2d");
export var forgotPassword = createServerReference("b769977de6ee3e0fef3bccf91283a3d5bfefc64c");
export var resetPassword = createServerReference("e4b7ae9082d58dec8f24bac9ea993cfcf781263d");
export var authenticate = createServerReference("e9d2ca670b80aab220634b9ab4a796b44765c9d1");
export var signOut = createServerReference("cbc42b5152fe576c93cc007ce88013f43aa3cc3e");
export var changePassword = createServerReference("ed38acc4fc053fa7662719642bd6e83960bca30b");

