"use client";

import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import superjson from "superjson";

import type { AppRouter } from "@gsa/api/types";

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 30 * 1000,
      },
    },
  });

let clientQueryClientSingleton: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  }
  // Browser: use singleton pattern to keep the same query client
  if (!clientQueryClientSingleton) {
    clientQueryClientSingleton = createQueryClient();
  }
  return clientQueryClientSingleton;
};

export const api = createTRPCReact<AppRouter>();

export function TRPCReactProvider(props: {
  children: React.ReactNode;
  authToken: string;
}) {
  const queryClient = getQueryClient();

  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        loggerLink({
          enabled: () => true,
        }),
        // process.env.NODE_ENV === "production" ?
        httpBatchLink({
          transformer: superjson,
          url: `${getBaseUrl()}/api/trpc`,
          headers: () => {
            return {
              Authorization: `Bearer ${props.authToken}`,
              "x-trpc-source": "react",
            };
          },
          // headers() {
          //   return {
          //     Authorization: `Bearer ${parsedCookies.__pxyz_session}`,
          //     "x-trpc-source": "react",
          //   }
          // },
        }),
        // : httpLink({
        //     transformer: superjson,
        //     url: getBaseUrl() + "/api/trpc",
        //     // headers() {
        //     //   return {
        //     //     Authorization: `Bearer ${parsedCookies.__pxyz_session}`,
        //     //     "x-trpc-source": "react",
        //     //   }
        //     // },
        //   }),
      ],
    }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <api.Provider client={trpcClient} queryClient={queryClient}>
        {props.children}
      </api.Provider>
    </QueryClientProvider>
  );
}

const getBaseUrl = () => {
  // if (typeof window !== "undefined") return window.location.origin;
  // if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  // return `http://localhost:${process.env.PORT ?? 3000}`;
  return `${process.env.NEXT_PUBLIC_API_URL}`;
};
