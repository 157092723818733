"use client";

import { usePathname } from "next/navigation";

import { cn } from "@gsa/ui/lib/utils";

import { getIsMenuOpen } from "~/lib/storage";

interface PageWrapper {
  className?: string;
  children?: React.ReactNode;
  mainBg?: string;
  border?: string;
}
export function PageWrapper({
  children,
  className,
  mainBg = "bg-transparent",
  border = "border-transparent",
}: PageWrapper) {
  const pathname = usePathname();
  const isMenuOpen = getIsMenuOpen();

  return (
    <main
      className={cn(
        "relative",
        pathname.startsWith("/dashboard/store/products/bulk-edit")
          ? "lg:pl-0"
          : "",
        mainBg,
        border,
        className,
        isMenuOpen ? "sm:pl-[300px]" : "sm:pl-[64px]",
      )}
    >
      <div className=" flex h-full w-full flex-col p-5 ">{children}</div>
    </main>
  );
}
