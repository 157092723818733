import { createId } from "@paralleldrive/cuid2";
import {
  index,
  integer,
  json,
  pgEnum,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";
import { DBCustomer } from "./customer";

export enum PaymentMethodProvider {
  Stripe = "stripe",
  AuthorizeNet = "authorize",
  Celero = "celero",
  Talkshop = "talkshop",
  Walmart = "walmart",
}
export const DBPaymentMethodProvider = pgEnum("store_payment_method_provider", [
  PaymentMethodProvider.Stripe,
  PaymentMethodProvider.AuthorizeNet,
  PaymentMethodProvider.Celero,
  PaymentMethodProvider.Talkshop,
  PaymentMethodProvider.Walmart,
]);

export enum PaymentMethodType {
  CeleroCreditCard = "celero-credit-card",
  CeleroCustomerCreditCard = "celero-customer-credit-card",
  CeleroPortal = "celero-portal",
  AuthorizeCreditCard = "authorize_credit_card",
  AuthorizeCustomerCreditCard = "authorize_customer_credit_card",
  CreditCard = "credit_card",
  Ach = "ach",
  Wire = "wire",
  Cash = "cash",
  Check = "check",
  Talkshop = "talkshop",
  Walmart = "walmart",
}
export const DBPaymentMethodType = pgEnum("store_payment_method_type", [
  PaymentMethodType.CeleroCreditCard,
  PaymentMethodType.CeleroCustomerCreditCard,
  PaymentMethodType.CeleroPortal,
  PaymentMethodType.AuthorizeCreditCard,
  PaymentMethodType.AuthorizeCustomerCreditCard,
  PaymentMethodType.CreditCard,
  PaymentMethodType.Ach,
  PaymentMethodType.Wire,
  PaymentMethodType.Cash,
  PaymentMethodType.Check,
  PaymentMethodType.Talkshop,
  PaymentMethodType.Walmart,
]);

export const DBPaymentMethod = pgTable(
  "store_payment_methods",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    provider: DBPaymentMethodProvider("provider").notNull(),
    type: DBPaymentMethodType("type").notNull(),
    userId: text("userId").references(() => DBUser.id, {
      onDelete: "cascade",
    }),
    customerId: text("customerId")
      .notNull()
      .references(() => DBCustomer.id, {
        onDelete: "cascade",
      }),
    data: json("data").notNull().$type<Record<string, string>>(),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
    deletedAt: timestamp("deletedAt", { mode: "date" }),
  },
  (paymentMethod) => ({
    userIdIdx: index("store_payment_methods-userId_idx").on(
      paymentMethod.userId
    ),
    customerIdIdx: index("store_payment_methods-customerId_idx").on(
      paymentMethod.customerId
    ),
    uniqueIdx: uniqueIndex("store_payment_methods-unique_idx").on(
      paymentMethod.userId,
      paymentMethod.customerId
    ),
  })
);

export enum PaymentStatus {
  Pending = "pending",
  Authorized = "authorized",
  Paid = "paid",
  Refunded = "refunded",
  Failed = "failed",
}
export const DBPaymentStatus = pgEnum("store_order_payment_status", [
  PaymentStatus.Pending,
  PaymentStatus.Authorized,
  PaymentStatus.Paid,
  PaymentStatus.Refunded,
  PaymentStatus.Failed,
]);

export const DBPayment = pgTable(
  "store_payments",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    provider: DBPaymentMethodProvider("provider"),
    type: DBPaymentMethodType("type").notNull(),
    userId: text("userId"),
    customerId: text("customerId"),
    paymentMethodId: text("paymentMethodId"),
    orderId: text("orderId").notNull(),
    amount: integer("amount").notNull(),
    currency: text("currency").notNull(),
    status: DBPaymentStatus("pending").notNull(),
    data: json("data").notNull().$type<Record<string, unknown>>(),
    errorCode: text("errorCode"),
    errorMessage: text("errorMessage"),
    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (payment) => ({
    userIdIdx: index("store_payments-userId_idx").on(payment.userId),

    customerIdIdx: index("store_payments-customerId_idx").on(
      payment.customerId
    ),

    paymentMethodIdIdx: index("store_payments-paymentMethodId_idx").on(
      payment.paymentMethodId
    ),

    orderIdIdx: index("store_payments-orderId_idx").on(payment.orderId),
  })
);
