"use client";

import { Suspense } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { TooltipProvider } from "@gsa/ui/components/ui/tooltip";
import { toast, Toaster } from "@gsa/ui/sonner";

// const AUTHORIZE_SANDBOX_SCRIPT = "https://jstest.authorize.net/v1/Accept.js";
// const AUTHORIZE_LIVE_SCRIPT = "https://js.authorize.net/v1/Accept.js";
// const AUTHORIZE_SCRIPT =
//   env.STAGE === "production" ? AUTHORIZE_LIVE_SCRIPT : AUTHORIZE_SANDBOX_SCRIPT;
// import { WebVitals } from "./ui/web-vitals";
export function Providers(props: {
  children: React.ReactNode;
  headers?: Headers;
}) {
  return (
    <>
      {/* <WebVitals /> */}
      <TooltipProvider>{props.children}</TooltipProvider>
      <Toaster />
      <Suspense fallback={null}>
        <CheckForToastMessage />
      </Suspense>
      {/* <Script
          id="superflowToolbarScript"
          async
          src="https://cdn.jsdelivr.net/npm/@usesuperflow/toolbar/superflow.min.js?apiKey=UyckDq5AXHWRbgkCHJU9&projectId=3566419118919312"
        /> */}

      {/* <Script src={AUTHORIZE_SCRIPT} /> */}
    </>
  );
}

function CheckForToastMessage() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const message = searchParams.get("toast");

  if (!message) {
    return null;
  }

  toast(message);

  const params = new URLSearchParams(searchParams);
  params.delete("toast");

  router.replace(`${pathname}?${params.toString()}`);

  return null;
}
