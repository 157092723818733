"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

import { Button } from "@gsa/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@gsa/ui/card";

import { PageWrapper } from "~/app/ui/layout/page-wrapper";

export default function ClientError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
    // Optionally log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <PageWrapper
      border="border-transparent"
      className=" h-screen items-center justify-center"
    >
      <Card className="mx-auto w-full lg:max-w-[400px]">
        <CardHeader className="text-center">
          <CardTitle>Something went wrong!</CardTitle>
          <CardDescription>
            {error?.message ?? "An unknown error occurred."}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Button className="w-full" onClick={() => reset()}>
            Try again
          </Button>
        </CardContent>
      </Card>
    </PageWrapper>
  );
}
