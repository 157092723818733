import { createId } from "@paralleldrive/cuid2";
import {
  decimal,
  index,
  integer,
  pgEnum,
  pgTable,
  serial,
  text,
  timestamp,
} from "drizzle-orm/pg-core";

import { DBUser } from "./auth";
import { DBCustomer } from "./customer";
import { DBPaymentMethod, DBPaymentStatus } from "./payment";

export const DBOrderSource = pgTable("store_order_sources", {
  id: text("id").notNull().primaryKey().$defaultFn(createId),
  name: text("name").notNull(),
  description: text("description"),
  createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
  updatedAt: timestamp("updatedAt", { mode: "date" }),
  archivedAt: timestamp("archivedAt", { mode: "date" }),
});

export enum OrderStatus {
  Pending = "pending",
  Paid = "paid",
  PaymentFailed = "payment_failed",
  Processing = "processing",
  Shipped = "shipped",
  Delivered = "delivered",
  Canceled = "canceled",
}
export const DBOrderStatus = pgEnum("store_order_status", [
  OrderStatus.Pending,
  OrderStatus.Paid,
  OrderStatus.PaymentFailed,
  OrderStatus.Processing,
  OrderStatus.Shipped,
  OrderStatus.Delivered,
  OrderStatus.Canceled,
]);

export enum OrderFulfillmentStatus {
  Unfulfilled = "unfulfilled",
  Fulfilled = "fulfilled",
  Partial = "partial",
  Restocked = "restocked",
}
export const DBOrderFulfillmentStatus = pgEnum(
  "store_order_fulfillment_status",
  [
    OrderFulfillmentStatus.Unfulfilled,
    OrderFulfillmentStatus.Fulfilled,
    OrderFulfillmentStatus.Partial,
    OrderFulfillmentStatus.Restocked,
  ]
);

export enum OrderShipmentStatus {
  Pending = "pending",
  Shipped = "shipped",
  Delivered = "delivered",
  Returned = "returned",
}
export const DBShipmentStatus = pgEnum("store_shipment_status", [
  OrderShipmentStatus.Pending,
  OrderShipmentStatus.Shipped,
  OrderShipmentStatus.Delivered,
  OrderShipmentStatus.Returned,
]);

export const DBOrder = pgTable(
  "orders",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),

    ip: text("ip"),
    userAgent: text("userAgent"),

    userId: text("userId").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    customerId: text("customerId").references(() => DBCustomer.id, {
      onDelete: "set null",
    }),

    salesRepId: text("salesRepId").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    sourceId: text("sourceId")
      .references(() => DBOrderSource.id, {
        onDelete: "set null",
      })
      .notNull(),

    paymentMethodId: text("paymentMethodId").references(
      () => DBPaymentMethod.id,
      {
        onDelete: "set null",
      }
    ),

    salesRepCommissionRate: decimal("salesRepCommissionRate", {
      precision: 10,
      scale: 2,
    }).default("0.0"),

    customerName: text("customerName").notNull(),
    customerEmail: text("customerEmail"),
    customerPhone: text("customerPhone"),

    shippingAddress1: text("shipping_address1"),
    shippingAddress2: text("shipping_address2"),
    shippingCity: text("shipping_city"),
    shippingState: text("shipping_state"),
    shippingZip: text("shipping_zip"),
    shippingCountry: text("shipping_country"),
    shippingProvince: text("shipping_province"),
    shippingCompany: text("shipping_company"),

    billingAddress1: text("billing_address1"),
    billingAddress2: text("billing_address2"),
    billingCity: text("billing_city"),
    billingState: text("billing_state"),
    billingZip: text("billing_zip"),
    billingCountry: text("billing_country"),
    billingProvince: text("billing_province"),
    billingCompany: text("billing_company"),

    shipping: integer("shipping").notNull(),
    taxes: integer("taxes").notNull(),
    total: integer("total").notNull(),

    shippingStatus: DBShipmentStatus("shippingStatus").notNull(),
    paymentStatus: DBPaymentStatus("paymentStatus").notNull(),
    fulfillmentStatus: DBOrderFulfillmentStatus("fulfillmentStatus").notNull(),

    status: DBOrderStatus("status").notNull(),

    paidAt: timestamp("paidAt", { mode: "date" }),
    fulfilledAt: timestamp("fulfilledAt", { mode: "date" }),
    shippedAt: timestamp("shippedAt", { mode: "date" }),
    refundedAt: timestamp("refundedAt", { mode: "date" }),
    canceledAt: timestamp("canceledAt", { mode: "date" }),
    deliveredAt: timestamp("deliveredAt", { mode: "date" }),
    completedAt: timestamp("completedAt", { mode: "date" }),

    invoiceId: serial("invoiceId"),

    talkshopShipmentId: text("talkshopShipmentId"),
    walmartOrderId: text("walmartOrderId"),

    shipstationOrderStatus: text("shipstationOrderStatus"),
    shipstationCarrierCode: text("shipstationCarrierCode"),
    shipstationServiceCode: text("shipstationServiceCode"),
    shipstationPackageCode: text("shipstationPackageCode"),
    shipstationConfirmation: text("shipstationConfirmation"),
    shipstationOrderId: integer("shipstationOrderId"),
    shipstationShipDate: timestamp("shipstationShipDate", { mode: "date" }),
    shipstationTrackingNumber: text("shipstationTrackingNumber"),

    heritagePOSentAt: timestamp("heritagePOSentAt", { mode: "date" }),
    heritageOrderID: text("heritageOrderID"),
    heritagePOError: text("heritagePOError"),

    heritageItemsPickedUpBy: text("heritageItemsPickedUpBy").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),
    heritageItemsPickedUpAt: timestamp("heritageItemsPickedUpAt", {
      mode: "date",
    }),

    fiztradeItemsPickedUpBy: text("fiztradeItemsPickedUpBy").references(
      () => DBUser.id,
      {
        onDelete: "set null",
      }
    ),
    fiztradeItemsPickedUpAt: timestamp("fiztradeItemsPickedUpAt", {
      mode: "date",
    }),

    markedPaidBy: text("markedPaidBy").references(() => DBUser.id, {
      onDelete: "set null",
    }),
    markedShippedBy: text("markedShippedBy").references(() => DBUser.id, {
      onDelete: "set null",
    }),
    markedDeliveredBy: text("markedDeliveredBy").references(() => DBUser.id, {
      onDelete: "set null",
    }),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (order) => ({
    userIdIdx: index("orders-userId_idx").on(order.userId),
    customerIdIdx: index("orders-customerId_idx").on(order.customerId),
    salesRepIdIdx: index("orders-salesRepId_idx").on(order.salesRepId),
    sourceIdIdx: index("orders-sourceId_idx").on(order.sourceId),
    paymentMethodIdIdx: index("orders-paymentMethodId_idx").on(
      order.paymentMethodId
    ),
    talkshopShipmentIdIdx: index("orders-talkshopShipmentId_idx").on(
      order.talkshopShipmentId
    ),
  })
);

export const DBOrderItem = pgTable(
  "order_items",
  {
    id: text("id").notNull().primaryKey().$defaultFn(createId),
    orderId: text("orderId").notNull(),
    productId: text("productId").notNull(),
    quantity: integer("quantity").notNull(),
    price: integer("price").notNull(),
    cost: integer("cost"),

    createdAt: timestamp("createdAt", { mode: "date" }).notNull().defaultNow(),
    updatedAt: timestamp("updatedAt", { mode: "date" }),
  },
  (example) => ({
    orderIdIdx: index("order_items-orderId_idx").on(example.orderId),
    productIdIdx: index("order_items-productId_idx").on(example.productId),
  })
);
