// app/PostHogPageView.tsx
"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

import { api } from "~/trpc/react";

export default function PostHogPageView(): null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  const userQuery = api.auth.session.useQuery();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url,
      });

      if (userQuery.data) {
        const user = userQuery.data.user;
        posthog.identify(
          user.id, // Replace 'distinct_id' with your user's unique identifier
          { email: user.email, name: user.name }, // optional: set additional user properties
        );
      }
    }
  }, [pathname, searchParams, posthog, userQuery.data]);

  return null;
}
