import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import { DBApiKey } from "./apiKey";
import { DBSession, DBUser } from "./auth";
import { DBCart, DBCartItem } from "./cart";
import { DBCategory, DBProductCategory } from "./category";
import {
  CharityRequestPaymentType,
  CharityRequestPercents,
  CharityRequestStatus,
  DBCharityRequest,
  DBCharityRequestImage,
  DBCharityRequestItem,
  DBCharityRequestItemImage,
} from "./charity";
import { DBCollection, DBCollectionProduct } from "./collection";
import { DBCommissionCategory } from "./commissions";
import {
  ConsignmentItemPaymentStatus,
  ConsignmentStatus,
  DBConsignment,
  DBConsignmentImage,
  DBConsignmentItem,
  DBConsignmentItemImage,
} from "./consignment";
import { DBCustomer } from "./customer";
import { DBFile, FileableType } from "./file";
import { DBFormSubmission } from "./formSubmission";
import {
  DBGoldslayerLead,
  DBGoldslayerLeadImage,
  DBGoldslayerLeadItem,
  DBGoldslayerLeadItemImage,
  GoldslayerLeadPaymentType,
  GoldslayerLeadStatus,
} from "./goldslayer";
import { DBMetalPrice } from "./metalprice";
import { DBNote, DBNoteReading } from "./note";
import {
  DBOrder,
  DBOrderItem,
  DBOrderSource,
  OrderFulfillmentStatus,
  OrderShipmentStatus,
  OrderStatus,
} from "./order";
import {
  DBPayment,
  DBPaymentMethod,
  PaymentMethodProvider,
  PaymentMethodType,
  PaymentStatus,
} from "./payment";
import {
  DBProduct,
  DBProductImage,
  DBProductStatus,
  DBProductView,
  DBProductWishlistItem,
  ProductInventoryLevel,
  ProductSource,
  ProductStatus,
} from "./product";
import { DBWebhook } from "./webhook";

export const createUserSchema = createInsertSchema(DBUser).merge(
  z.object({
    password: z.string().min(6),
    roles: z.array(z.string()).nullable().optional(),
  })
);

export const signUpSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  password: z.string().min(6),
});
export type SignUpSchema = z.infer<typeof signUpSchema>;
export type CreateUserSchema = z.infer<typeof createUserSchema>;
export const selectUserSchema = createSelectSchema(DBUser).merge(
  z.object({
    roles: z.array(z.string()).nullable(),
  })
);
export type UserSchema = z.infer<typeof selectUserSchema>;

export const signInSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(1),
  })
  .transform(({ email, password }) => ({
    email: email.trim().toLowerCase(),
    password: password.trim(),
  }));

export type SignInSchema = z.infer<typeof signInSchema>;

export const forgotPasswordSchema = z
  .object({
    email: z.string().email(),
  })
  .transform(({ email }) => ({ email: email.trim().toLowerCase() }));

export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const resetPasswordSchema = z.object({
  token: z.string(),
  password: z.string().min(6),
});

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const updateUserSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable().optional(),
  email: z.string().email().optional(),
  emailVerified: z.date().nullable().optional(),
  password: z
    .string()
    .nullable()
    .optional()
    .refine((val) => !(!val || val.length < 6), {
      message: "Password must be at least 6 characters long",
    }),
  roles: z.array(z.string()).nullable().optional(),
  ringCentralNumber: z.string().nullable().optional(),
  salesCommission: z
    .union([z.number(), z.string()])
    .nullable()
    .optional()
    .transform((strval) =>
      typeof strval === "string" && strval ? Number(strval) : strval
    ),
});

export type UpdateUserSchema = z.infer<typeof updateUserSchema>;
export const createSessionSchema = createInsertSchema(DBSession);
export type CreateSessionSchema = z.infer<typeof createSessionSchema>;
export const selectSessionSchema = createSelectSchema(DBSession);
export type SessionSchema = z.infer<typeof selectSessionSchema>;

export const createCartSchema = createInsertSchema(DBCart);
export type CreateCartSchema = z.infer<typeof createCartSchema>;
export const updateCartSchema = createInsertSchema(DBCart).merge(
  z.object({
    id: z.string(),
  })
);
export type UpdateCartSchema = z.infer<typeof updateCartSchema>;
export const selectCartSchema = createSelectSchema(DBCart);
export type CartSchema = z.infer<typeof selectCartSchema>;

export const cartWithFullDataSchema = selectCartSchema.merge(
  z.object({
    totalQuantity: z.number(),
    cost: z.object({
      subtotalAmount: z.number(),
      totalTaxAmount: z.number(),
      totalShippingAmount: z.number(),
      totalAmount: z.number(),
    }),
    salesRep: z
      .object({
        name: z.string(),
        ringCentralNumber: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
    items: z.array(
      z.object({
        id: z.string(),
        productId: z.string(),
        quantity: z.number(),
        product: z.object({
          id: z.string(),
          title: z.string(),
          slug: z.string(),
          price: z.number(),
          description: z.string(),
          publicNote: z.string(),
          status: z.nativeEnum(ProductStatus),
          inventoryLevel: z.nativeEnum(ProductInventoryLevel),
          sku: z.string(),
          source: z.nativeEnum(ProductSource),
          stockedQuantity: z.number(),
          reservedQuantity: z.number(),
          meta: z.any(),
          images: z.array(
            z.object({
              id: z.string(),
              path: z.string(),
              width: z.number(),
              height: z.number(),
            })
          ),
          createdAt: z.date(),
          updatedAt: z.date(),
        }),
      })
    ),
  })
);

export type CartWithFullDataSchema = z.infer<typeof cartWithFullDataSchema>;

export const createCartItemSchema = createInsertSchema(DBCartItem);
export type CreateCartItemSchema = z.infer<typeof createCartItemSchema>;
export const selectCartItemSchema = createSelectSchema(DBCartItem);
export type CartItemSchema = z.infer<typeof selectCartItemSchema>;

// Schema for createing a category - can be used to validate API requests
export const createCategorySchema = createInsertSchema(DBCategory).merge(
  z.object({
    slug: z.string().optional(),
  })
);
export type CreateCategorySchema = z.infer<typeof createCategorySchema>;
export const updateCategorySchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  slug: z.string().optional(),

  parentId: z.string().optional(),
  image: z.string().optional(),
  productCount: z.number().optional(),
});
export type UpdateCategorySchema = z.infer<typeof updateCategorySchema>;
export const selectCategorySchema = createSelectSchema(DBCategory);
export type CategorySchema = z.infer<typeof selectCategorySchema>;
export type CategorySchemaWithParent = CategorySchema & {
  parent: CategorySchemaWithParent | null;
  children: CategorySchemaWithParent[];
};

export const createCollectionSchema = createInsertSchema(DBCollection);
export type CreateCollectionSchema = z.infer<typeof createCollectionSchema>;
export const updateCollectionSchema = createInsertSchema(DBCollection).merge(
  z.object({
    id: z.string(),
  })
);
export type UpdateCollectionSchema = z.infer<typeof updateCollectionSchema>;
export const selectCollectionSchema = createSelectSchema(DBCollection);
export type CollectionSchema = z.infer<typeof selectCollectionSchema>;

export type CollectionWithProductsSchema = CollectionSchema & {
  collectionProducts: CollectionProductSchema[];
};

export type CategoryWithProductsSchema = CategorySchema & {
  categoryProducts: ProductCategorySchema[];
};

export const createCollectionProductSchema =
  createInsertSchema(DBCollectionProduct);
export type CreateCollectionProductSchema = z.infer<
  typeof createCollectionProductSchema
>;
export const selectCollectionProductSchema = createSelectSchema(
  DBCollectionProduct
).merge(
  z.object({
    collection: selectCollectionSchema,
  })
);
export type CollectionProductSchema = z.infer<
  typeof selectCollectionProductSchema
>;

export const createMetalPriceSchema = createInsertSchema(DBMetalPrice);
export type CreateMetalPriceSchema = z.infer<typeof createMetalPriceSchema>;
export const selectMetalPriceSchema = createSelectSchema(DBMetalPrice);
export type MetalPriceSchema = z.infer<typeof selectMetalPriceSchema>;

export const createOrderSourceSchema = createInsertSchema(DBOrderSource);
export type CreateOrderSourceSchema = z.infer<typeof createOrderSourceSchema>;

export const updateOrderSourceSchema = createInsertSchema(DBOrderSource).merge(
  z.object({
    id: z.string(),
  })
);
export type UpdateOrderSourceSchema = z.infer<typeof updateOrderSourceSchema>;

export const selectOrderSourceSchema = createSelectSchema(DBOrderSource).merge(
  z.object({
    ordersCount: z.number().optional(),
  })
);
export type OrderSourceSchema = z.infer<typeof selectOrderSourceSchema>;
export const paymentMethodDataSchema = z.object({
  paymentProfileId: z.string().optional(),
  dataDescriptor: z.string().optional(),
  dataValue: z.string().optional(),
  token: z.string().optional(),
  cardType: z.string(),
  last4: z.string(),
  expMonth: z.string(),
  expYear: z.string(),
});
export type PaymentMethodDataSchema = z.infer<typeof paymentMethodDataSchema>;

// // Schema for creating a customer - can be used to validate API requests
export const opaqueDataSchema = z.object({
  dataDescriptor: z.string(),
  dataValue: z.string(),
});
export type OpaqueDataSchema = z.infer<typeof opaqueDataSchema>;
export const cardDataSchema = z.object({
  cardType: z.string(),
  last4: z.string(),
  expMonth: z.string(),
  expYear: z.string(),
});
export type CardDataSchema = z.infer<typeof cardDataSchema>;
export const createCustomerSchema = createInsertSchema(DBCustomer).merge(
  z.object({
    userId: z.string().optional().nullable(),
    email: z.string().email(),
    phone: z.string().nullable(),
    name: z.string(),
    billingAddress2: z.string().nullable().optional(),
    billingCompany: z.string().nullable().optional(),

    data: opaqueDataSchema.optional().nullable().optional(),
    shippingSameAsBilling: z.boolean().default(true),

    dealer: z.boolean().default(false),
    dealerName: z.string().optional(),

    shippingAddress1: z.string().nullable().optional(),
    shippingAddress2: z.string().nullable().optional(),
    shippingCity: z.string().nullable().optional(),
    shippingState: z.string().nullable().optional(),
    shippingZip: z.string().nullable().optional(),
    shippingCountry: z.string().nullable().optional(),
    shippingCompany: z.string().nullable().optional(),
  })
);

export const updateCustomerSchema = createInsertSchema(DBCustomer)
  .merge(
    z.object({
      id: z.string(),
    })
  )
  .omit({
    createdAt: true,
    updatedAt: true,
  });

export type UpdateCustomerSchema = z.infer<typeof updateCustomerSchema>;

export type CreateCustomerSchema = z.infer<typeof createCustomerSchema>;
export const selectCustomerSchema = createSelectSchema(DBCustomer);
export type CustomerSchema = z.infer<typeof selectCustomerSchema>;

export const selectPaymentMethodSchema = createSelectSchema(
  DBPaymentMethod
).merge(
  z.object({
    data: paymentMethodDataSchema,
    ordersCount: z.number().optional(),
  })
);
export type PaymentMethodSchema = z.infer<typeof selectPaymentMethodSchema>;

// export const createOrderSchema = createInsertSchema(DBOrder).merge(
//   z.object({
//     items: z.array(
//       z.object({
//         productId: z.string(),
//         quantity: z.number(),
//       }),
//     ),
//   }),
// );
// export type CreateOrderSchema = z.infer<typeof createOrderSchema>;

export const creditCardInputSchema = z.object({
  cardNumber: z.string(),
  month: z.string(),
  year: z.string(),
  cardCode: z.string(),
  zip: z.string().optional(),
  fullName: z.string(),
});

export type CreditCardInputSchema = z.infer<typeof creditCardInputSchema>;

export const authorizeNetCreditCardPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.AuthorizeCreditCard),
  // cardInput: creditCardInputSchema,
  cardData: cardDataSchema,
  opaqueData: opaqueDataSchema,
});

export type AuthorizeNetCreditCardPaymentSchema = z.infer<
  typeof authorizeNetCreditCardPaymentSchema
>;

export const authorizeNetCreditCardPaymentInputSchema = z.object({
  type: z.literal(PaymentMethodType.AuthorizeCreditCard),
  cardInput: creditCardInputSchema,
});

export const celeroCreditCardPaymentInputSchema = z.object({
  type: z.literal(PaymentMethodType.CeleroCreditCard),
  token: z.string(),
  cardData: cardDataSchema,
});
export type CeleroCreditCardPaymentInputSchema = z.infer<
  typeof celeroCreditCardPaymentInputSchema
>;

export type AuthorizeNetCreditCardPaymentInputSchema = z.infer<
  typeof authorizeNetCreditCardPaymentInputSchema
>;

export const authorizeNetCustomerCreditCardPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.AuthorizeCustomerCreditCard),
  paymentMethod: z.object({
    paymentMethodId: z.string(),
  }),
});

export type AuthorizeNetCustomerCreditCardPaymentSchema = z.infer<
  typeof authorizeNetCustomerCreditCardPaymentSchema
>;

export const celeroCustomerCreditCardPaymentInputSchema = z.object({
  type: z.literal(PaymentMethodType.CeleroCustomerCreditCard),
  paymentMethod: z.object({
    paymentMethodId: z.string(),
  }),
});

export const checkPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Check),
});

export const talkshopPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Talkshop),
});

export const walmartPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Walmart),
});

export type CheckPaymentSchema = z.infer<typeof checkPaymentSchema>;

export const cashPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Cash),
});

export type CashPaymentSchema = z.infer<typeof cashPaymentSchema>;

export const wirePaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Wire),
});

export type WirePaymentSchema = z.infer<typeof wirePaymentSchema>;

export const achPaymentSchema = z.object({
  type: z.literal(PaymentMethodType.Ach),
});

export type AchPaymentSchema = z.infer<typeof achPaymentSchema>;

export const existingCustomerSchema = z.object({
  type: z.literal("existing"),
  customerId: z.string(),
});
export type ExistingCustomerSchema = z.infer<typeof existingCustomerSchema>;

export const newCustomerSchema = z.object({
  type: z.literal("new"),
  customerName: z.string(),
  customerEmail: z.string(),
  customerPhone: z.string(),

  billingAddress1: z.string(),
  billingAddress2: z.string().optional(),
  billingCity: z.string(),
  billingState: z.string(),
  billingZip: z.string(),
  billingCountry: z.string(),
  billingCompany: z.string().optional(),

  shippingAddress1: z.string(),
  shippingAddress2: z.string().optional(),
  shippingCity: z.string(),
  shippingState: z.string(),
  shippingZip: z.string(),
  shippingCountry: z.string(),
  shippingCompany: z.string().optional(),
});

export type NewCustomerSchema = z.infer<typeof newCustomerSchema>;

export const createOrderSchema = z.object({
  ip: z.string().nullable().optional(),
  userAgent: z.string().nullable().optional(),
  source: selectOrderSourceSchema,
  salesRep: selectUserSchema.optional(),
  talkshopShipmentId: z.string().optional(),
  walmartOrderId: z.string().optional(),

  customer: z.discriminatedUnion("type", [
    existingCustomerSchema,
    newCustomerSchema,
  ]),

  payment: z.discriminatedUnion("type", [
    celeroCreditCardPaymentInputSchema,
    celeroCustomerCreditCardPaymentInputSchema,
    authorizeNetCreditCardPaymentSchema,
    authorizeNetCustomerCreditCardPaymentSchema,
    checkPaymentSchema,
    cashPaymentSchema,
    wirePaymentSchema,
    achPaymentSchema,
    talkshopPaymentSchema,
    walmartPaymentSchema,
  ]),

  items: z.array(
    z.object({
      productId: z.string(),
      quantity: z.number(),
    })
  ),
});

export type CreateOrderSchema = z.infer<typeof createOrderSchema>;

export const createOrderPaymentLinkSchema = z.object({
  sourceId: z.string(),
  salesRepId: z.string(),
  items: z.array(
    z.object({
      productId: z.string(),
      quantity: z.number(),
    })
  ),
});

export type CreateOrderPaymentLinkSchema = z.infer<
  typeof createOrderPaymentLinkSchema
>;

export const insertOrderSchema = createInsertSchema(DBOrder);
export type InsertOrderSchema = z.infer<typeof insertOrderSchema>;

// export type CreateOrderWithCustomerSchema = z.infer<
//   typeof createOrderSchema
// >;

// export const createOrderWithoutCustomerIdSchema = createOrderSchema
//   .omit({
//     customerId: true,
//     sourceId: true,
//     shipping: true,
//     taxes: true,
//     total: true,
//     shippingStatus: true,
//     fulfillmentStatus: true,
//     paymentStatus: true,
//     status: true,
//   })
//   .merge(
//     z.object({
//       customerName: z.string(),
//       customerEmail: z.string(),
//       customerPhone: z.string().optional(),
//     }),
//   );

// export type CreateOrderWithoutCustomerIdSchema = z.infer<
//   typeof createOrderWithoutCustomerIdSchema
// >;

export const updateOrderSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(OrderStatus).optional(),

  paymentStatus: z.nativeEnum(PaymentStatus).optional(),
  fulfillmentStatus: z.nativeEnum(OrderFulfillmentStatus).optional(),
  shippingStatus: z.nativeEnum(OrderShipmentStatus).optional(),

  paidAt: z.date().optional(),
  fulfilledAt: z.date().optional(),
  shippedAt: z.date().optional(),
  refundedAt: z.date().optional(),
  canceledAt: z.date().optional(),
  completedAt: z.date().optional(),

  shipstationOrderStatus: z.string().nullable().optional(),
  shipstationCarrierCode: z.string().nullable().optional(),
  shipstationServiceCode: z.string().nullable().optional(),
  shipstationPackageCode: z.string().nullable().optional(),
  shipstationConfirmation: z.string().nullable().optional(),
  shipstationOrderId: z.number().nullable().optional(),
  shipstationShipDate: z.date().nullable().optional(),
  shipstationTrackingNumber: z.string().nullable().optional(),

  markedPaidBy: z.string().nullable().optional(),

  heritagePOSentAt: z.date().nullable().optional(),
  heritageOrderID: z.string().nullable().optional(),
  heritagePOError: z.string().nullable().optional(),

  heritageItemsPickedUpBy: z.string().nullable().optional(),
  heritageItemsPickedUpAt: z.date().nullable().optional(),

  fiztradeItemsPickedUpBy: z.string().nullable().optional(),
  fiztradeItemsPickedUpAt: z.date().nullable().optional(),

  markedShippedBy: z.string().nullable().optional(),
  salesRepId: z.string().nullable().optional(),
});

export type UpdateOrderSchema = z.infer<typeof updateOrderSchema>;

// export const createOrderSchema = z.object({
//   status: z.nativeEnum(OrderStatus),
//   sourceId: z.string()
//   salesRepId: z.string().uuid().optional(),

//   items: z.array(
//     z.object({
//       productId: z.string(),
//       quantity: z.number(),
//     }),
//   ),

//   paymentMethodId: z.string().nullable().optional(),
//   paymentType: z.nativeEnum(PaymentMethodType).nullable().optional(),
//   paymentProvider: z.nativeEnum(PaymentMethodProvider).nullable().optional(),
//   paymentData: z.any().nullable().optional(),
// });

// export const createOrderWithCustomerSchema = createOrderSchema.merge(
//   z.object({
//     customerId: z.string(),
//   }),
// );

// export type CreateOrderWithCustomerSchema = z.infer<
//   typeof createOrderWithCustomerSchema
// >;

// export const createOrderWithoutCustomerSchema = createOrderSchema.merge(
//   z.object({
//     customerName: z.string(),
//     customerEmail: z.string(),
//     customerPhone: z.string(),

//     paymentType: z.nativeEnum(PaymentMethodType).nullable().optional(),
//     paymentProvider: z.nativeEnum(PaymentMethodProvider).nullable().optional(),
//     paymentData: z.any().nullable().optional(),

//     shippingAddress1: z.string(),
//     shippingAddress2: z.string().nullable().optional(),
//     shippingCity: z.string(),
//     shippingState: z.string(),
//     shippingZip: z.string(),
//     shippingCountry: z.string(),
//     shippingCompany: z.string().nullable().optional(),

//     // cardDescriptor: z.string().nullable().optional(),
//     // cardNonce: z.string().nullable().optional(),
//   }),
// );

export const createPaymentMethodSchema = createInsertSchema(
  DBPaymentMethod
).merge(
  z.object({
    userId: z.string().nullable().optional(),
    customerId: z.string(),
    type: z.nativeEnum(PaymentMethodType),
    provider: z.nativeEnum(PaymentMethodProvider),

    data: paymentMethodDataSchema,
  })
);
export type CreatePaymentMethodSchema = z.infer<
  typeof createPaymentMethodSchema
>;

// Schema for createing a product - can be used to validate API requests
export const createProductSchema = createInsertSchema(DBProduct).merge(
  z.object({
    sku: z.string().nullable().optional(),

    categoryIds: z.array(z.string()).nullable().optional(),
    collectionIds: z.array(z.string()).nullable().optional(),

    status: z.enum(DBProductStatus.enumValues).default(ProductStatus.Draft),
  })
);
export type CreateProductSchema = z.infer<typeof createProductSchema>;
export const updateProductSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  price: z.number().optional(),
  cost: z.number().optional(),
  sku: z.string().optional(),
  description: z.string().optional(),
  publicNote: z.string().optional(),
  slug: z.string().optional(),
  status: z.nativeEnum(ProductStatus).optional(),
  stockedQuantity: z.number().optional(),
  reservedQuantity: z.number().optional(),
  inventoryLevel: z.nativeEnum(ProductInventoryLevel).optional(),
  commissionCategoryId: z.string().optional(),

  categoryIds: z.array(z.string()).nullable().optional(),
  collectionIds: z.array(z.string()).nullable().optional(),

  walmartSyncEnabled: z.boolean().nullable().optional(),
  walmartProductId: z.string().max(255).nullable().optional(),
  walmartLastSyncedAt: z.date().nullable().optional(),

  meta: z
    .object({
      year: z.string().nullable().optional(),
      denomination: z.string().nullable().optional(),
      grader: z.string().nullable().optional(),
      grade: z.string().nullable().optional(),
    })
    .optional(),

  updatedAt: z.date().optional(),
});
export type UpdateProductSchema = z.infer<typeof updateProductSchema>;
export const updateProductMetaSchema = z.object({
  id: z.string(),
  year: z.string().nullable().optional(),
  denomination: z.string().nullable().optional(),
  grader: z.string().nullable().optional(),
  grade: z.string().nullable().optional(),
  weight: z.string().nullable().optional(),
});
export type UpdateProductMetaSchema = z.infer<typeof updateProductMetaSchema>;

export const selectProductSchema = createSelectSchema(DBProduct).merge(
  z.object({ meta: z.any() })
);
export type ProductSchema = z.infer<typeof selectProductSchema>;

export type ProductWithRelations = ProductSchema & {
  viewCount: number;
  orderCount: number;
  categories: Pick<CategorySchema, "id" | "title" | "slug">[];
  collections: Pick<CollectionSchema, "id" | "title" | "slug" | "featured">[];
  commissionCategory:
    | Pick<CommissionCategorySchema, "id" | "name" | "percent">
    | null
    | undefined;
  images: Pick<
    ProductImageSchema,
    "order" | "url" | "path" | "width" | "height"
  >[];
};

// export type ProductListResult = ProductSchema & {
//   categories: Pick<CategorySchema, "id" | "slug" | "title">[];
//   collections: Pick<CollectionSchema, "id" | "slug" | "title">[];
//   images: Pick<
//     ProductImageSchema,
//     "id" | "order" | "url" | "path" | "width" | "height"
//   >[];
// };

// Schema for createing a product category - can be used to validate API requests
export const createProductCategorySchema =
  createInsertSchema(DBProductCategory);
export type CreateProductCategorySchema = z.infer<
  typeof createProductCategorySchema
>;
export const selectProductCategorySchema = createSelectSchema(
  DBProductCategory
).merge(
  z.object({
    category: selectCategorySchema,
  })
);
export type ProductCategorySchema = z.infer<typeof selectProductCategorySchema>;

export const deleteCategoryProductSchema = z.object({
  productId: z.string().cuid2(),
  categoryId: z.string().cuid2(),
});

export type DeleteCategoryProductSchema = z.infer<
  typeof deleteCategoryProductSchema
>;

export const deleteCollectionProductSchema = z.object({
  productId: z.string().cuid2(),
  collectionId: z.string().cuid2(),
});

export type DeleteCollectionProductSchema = z.infer<
  typeof deleteCollectionProductSchema
>;

// export type CreateOrderWithoutCustomerSchema = z.infer<
//   typeof createOrderWithoutCustomerSchema
// >;

export const selectOrderSchema = createSelectSchema(DBOrder);
export type OrderSchema = z.infer<typeof selectOrderSchema>;

export const getOrderSchema = z.union([
  z.object({
    id: z.string(),
  }),
  z.object({
    invoiceId: z.number(),
  }),
]);
export type GetOrderSchema = z.infer<typeof getOrderSchema>;

export const createProductViewSchema = createInsertSchema(DBProductView);
export type CreateProductViewSchema = z.infer<typeof createProductViewSchema>;
export const selectProductViewSchema = createSelectSchema(DBProductView);
export type ProductViewSchema = z.infer<typeof selectProductViewSchema>;

export const createProductImageSchema = createInsertSchema(
  DBProductImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type CreateProductImageSchema = z.infer<typeof createProductImageSchema>;
export const updateProductImageSchema = z.object({
  id: z.string(),
  order: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  meta: z.any().optional(),
  featured: z.boolean().optional(),
  url: z.string().optional(),
  productId: z.string().optional(),
  processed: z.boolean().optional(),
});

export const overrideSalesRepSchema = z.object({
  id: z.string(),
  salesRepId: z.string(),
});
export type OverrideSalesRepSchema = z.infer<typeof overrideSalesRepSchema>;

export type UpdateProductImageSchema = z.infer<typeof updateProductImageSchema>;
export const selectProductImageSchema = createSelectSchema(
  DBProductImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type ProductImageSchema = z.infer<typeof selectProductImageSchema>;

export const createProductWishlistItemSchema = createInsertSchema(
  DBProductWishlistItem
);
export type CreateProductWishlistItemSchema = z.infer<
  typeof createProductWishlistItemSchema
>;
export const selectProductWishlistItemSchema = createSelectSchema(
  DBProductWishlistItem
);
export type ProductWishlistItemSchema = z.infer<
  typeof selectProductWishlistItemSchema
>;

export const createOrderItemSchema = createInsertSchema(DBOrderItem);
export type CreateOrderItemSchema = z.infer<typeof createOrderItemSchema>;
export const selectOrderItemSchema = createSelectSchema(DBOrderItem);
export type OrderItemSchema = z.infer<typeof selectOrderItemSchema>;

export const updateOrderItemSchema = createInsertSchema(DBOrderItem)
  .pick({
    quantity: true,
  })
  .merge(
    z.object({
      id: z.string(),
    })
  );

export type UpdateOrderItemSchema = z.infer<typeof updateOrderItemSchema>;

export const selectOrderItemWithProductSchema = selectOrderItemSchema.merge(
  z.object({
    product: selectProductSchema.merge(
      z.object({
        images: z.array(selectProductImageSchema),
      })
    ),
  })
);

export const createPaymentSchema = createInsertSchema(DBPayment).merge(
  z.object({
    data: z.any(),
  })
);
export type CreatePaymentSchema = z.infer<typeof createPaymentSchema>;
export const selectPaymentSchema = createSelectSchema(DBPayment).merge(
  z.object({
    data: z.any(),
    paymentMethod: selectPaymentMethodSchema.nullable().optional(),
    user: selectUserSchema
      .pick({
        id: true,
        name: true,
        email: true,
      })
      .nullable()
      .optional(),
    customer: selectCustomerSchema
      .pick({
        id: true,
        name: true,
        email: true,
      })
      .nullable()
      .optional(),
    order: selectOrderSchema
      .pick({
        id: true,
        invoiceId: true,
      })
      .nullable()
      .optional(),
  })
);
export type PaymentSchema = z.infer<typeof selectPaymentSchema> & {
  data: object;
};

export const selectOrderWithItemsSchema = selectOrderSchema.merge(
  z.object({
    items: z.array(selectOrderItemWithProductSchema),
    salesRep: selectUserSchema.optional(),
    source: selectOrderSourceSchema.optional(),
    payments: z.array(selectPaymentSchema),
    customer: selectCustomerSchema.optional(),
    data: z.any(),
  })
);
export type OrderWithItemsSchema = z.infer<typeof selectOrderWithItemsSchema>;

export interface ProductWithImages extends ProductSchema {
  images: ProductImageSchema[];
  commissionCategory: CommissionCategorySchema | null | undefined;
}
export interface OrderItemWithProduct extends OrderItemSchema {
  product: ProductWithImages;
}
export interface OrderWithProductImages extends OrderSchema {
  salesRep?:
    | Pick<UserSchema, "id" | "name" | "email" | "image" | "ringCentralNumber">
    | null
    | undefined;
  notesCount?: number;
  payments?: PaymentSchema[];
  items: OrderItemWithProduct[];
  source: OrderSourceSchema | null | undefined;
}

/* FormSubmission */
export const selectFormSubmissionSchema = createSelectSchema(
  DBFormSubmission
).merge(z.object({ data: z.any() }));
export type FormSubmissionSchema = z.infer<typeof selectFormSubmissionSchema>;

export const createFormSubmissionSchema = createInsertSchema(
  DBFormSubmission
).merge(z.object({ data: z.any() }));
export type CreateFormSubmissionSchema = z.infer<
  typeof createFormSubmissionSchema
>;

export const updateFormSubmissionSchema = createInsertSchema(
  DBFormSubmission
).merge(
  z.object({
    id: z.string(),
    data: z.any(),
  })
);
export type UpdateFormSubmissionSchema = z.infer<
  typeof updateFormSubmissionSchema
>;

/* ApiKey  */
export const selectApiKeySchema = createSelectSchema(DBApiKey).merge(
  z.object({
    user: z
      .object({
        id: z.string(),
        name: z.string().nullable(),
      })
      .nullable()
      .optional(),
    permissions: z.array(z.string()).optional().default(["*"]),
  })
);
export type ApiKeySchema = z.infer<typeof selectApiKeySchema>;

export const createApiKeySchema = createInsertSchema(DBApiKey)
  .omit({ key: true })
  .merge(
    z.object({
      userId: z.string().optional(),
      permissions: z.array(z.string()).optional().default(["*"]),
    })
  );
export type CreateApiKeySchema = z.infer<typeof createApiKeySchema>;

export const updateApiKeySchema = createInsertSchema(DBApiKey).merge(
  z.object({
    id: z.string(),
    permissions: z.array(z.string()).optional().default(["*"]),
  })
);
export type UpdateApiKeySchema = z.infer<typeof updateApiKeySchema>;

export const selectApiKeyWithUserSchema = selectApiKeySchema.merge(
  z.object({
    user: z
      .object({
        id: z.string(),
        name: z.string().nullable(),
      })
      .nullable()
      .optional(),
  })
);
export type ApiKeyWithUserSchema = z.infer<typeof selectApiKeyWithUserSchema>;

/* Webhook */
export const selectWebhookSchema = createSelectSchema(DBWebhook).merge(
  z.object({
    user: z
      .object({
        id: z.string(),
        name: z.string().nullable(),
      })
      .nullable()
      .optional(),
    events: z.array(z.string()).optional().default(["*"]),
  })
);
export type WebhookSchema = z.infer<typeof selectWebhookSchema>;

export const createWebhookSchema = createInsertSchema(DBWebhook).merge(
  z.object({
    userId: z.string().optional(),
    url: z.string().url(),
    events: z.array(z.string()).optional().default(["*"]),
  })
);
export type CreateWebhookSchema = z.infer<typeof createWebhookSchema>;

export const updateWebhookSchema = createInsertSchema(DBWebhook).merge(
  z.object({
    id: z.string(),
    url: z.string().url(),
    events: z.array(z.string()).optional().default(["*"]),
  })
);
export type UpdateWebhookSchema = z.infer<typeof updateWebhookSchema>;

export const selectWebhookWithUserSchema = selectWebhookSchema.merge(
  z.object({
    user: z
      .object({
        id: z.string(),
        name: z.string().nullable(),
      })
      .nullable()
      .optional(),
  })
);
export type WebhookWithUserSchema = z.infer<typeof selectWebhookWithUserSchema>;

export const createNoteSchema = createInsertSchema(DBNote);
export type CreateNoteSchema = z.infer<typeof createNoteSchema>;

export const updateNoteSchema = createInsertSchema(DBNote).merge(
  z.object({
    id: z.string(),
    note: z.string().optional(),
  })
);
export type UpdateNoteSchema = z.infer<typeof updateNoteSchema>;

export const selectNoteSchema = createSelectSchema(DBNote);
export type NoteSchema = z.infer<typeof selectNoteSchema>;

export const createNoteReadingSchema = createInsertSchema(DBNoteReading);
export type CreateNoteReadingSchema = z.infer<typeof createNoteReadingSchema>;

export const updateNoteReadingSchema = createInsertSchema(DBNoteReading).merge(
  z.object({
    id: z.string(),
    userId: z.string(),
    noteId: z.string(),
  })
);
export type UpdateNoteReadingSchema = z.infer<typeof updateNoteReadingSchema>;

export const selectNoteReadingSchema = createSelectSchema(DBNoteReading);
export type NoteReadingSchema = z.infer<typeof selectNoteReadingSchema>;

// consignment
export const createConsignmentSchema = createInsertSchema(DBConsignment);
export type CreateConsignmentSchema = z.infer<typeof createConsignmentSchema>;
export const selectConsignmentSchema = createSelectSchema(DBConsignment);
export type ConsignmentSchema = z.infer<typeof selectConsignmentSchema>;
export const updateConsignmentSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  details: z.string().optional(),
  briefDescription: z.string().optional(),
  verifiedContents: z.string().optional(),

  description: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  status: z.nativeEnum(ConsignmentStatus).optional(),
  shippingDate: z.date().optional(),
  shippingCarrier: z.string().optional(),
  shippingService: z.string().optional(),
  shippingPackage: z.string().optional(),
  shippingWeight: z.string().optional(),
  shippingWeightUnits: z.string().optional(),
  sourceId: z.string().optional(),
  salesRepId: z.string().optional(),
});
export type UpdateConsignmentSchema = z.infer<typeof updateConsignmentSchema>;

export const insertConsignmentImageSchema = createInsertSchema(
  DBConsignmentImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type InsertConsignmentImageSchema = z.infer<
  typeof insertConsignmentImageSchema
>;
export const createConsignmentImageSchema = z.object({
  consignmentId: z.string().cuid2(),
  contentType: z.string(),
  contentLength: z.number(),
});
export type CreateConsignmentImageSchema = z.infer<
  typeof createConsignmentImageSchema
>;
export const updateConsignmentImageSchema = z.object({
  id: z.string(),
  order: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  meta: z.any().optional(),
  featured: z.boolean().optional(),
  url: z.string().optional(),
  consignmentId: z.string().optional(),
  processed: z.boolean().optional(),
});
export type UpdateConsignmentImageSchema = z.infer<
  typeof updateConsignmentImageSchema
>;
export const selectConsignmentImageSchema = createSelectSchema(
  DBConsignmentImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type ConsignmentImageSchema = z.infer<
  typeof selectConsignmentImageSchema
>;

export const createConsignmentItemSchema = z.object({
  consignmentId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
  paymentStatus: z.nativeEnum(ConsignmentItemPaymentStatus).optional(),
});
export type CreateConsignmentItemSchema = z.infer<
  typeof createConsignmentItemSchema
>;

export const insertConsignmentItemSchema =
  createInsertSchema(DBConsignmentItem);
export type InsertConsignmentItemSchema = z.infer<
  typeof insertConsignmentItemSchema
>;
export const selectConsignmentItemSchema =
  createSelectSchema(DBConsignmentItem);
export type ConsignmentItemSchema = z.infer<typeof selectConsignmentItemSchema>;
export const updateConsignmentItemSchema = z.object({
  id: z.string(),
  lotNumber: z.string().optional(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
  paymentStatus: z.nativeEnum(ConsignmentItemPaymentStatus).optional(),
});
export type UpdateConsignmentItemSchema = z.infer<
  typeof updateConsignmentItemSchema
>;

export const upsertConsignmentItemSchema = z.object({
  consignmentId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
  paymentStatus: z.nativeEnum(ConsignmentItemPaymentStatus).optional(),
});
export type UpsertConsignmentItemSchema = z.infer<
  typeof upsertConsignmentItemSchema
>;

export const createConsignmentItemImageSchema = createInsertSchema(
  DBConsignmentItemImage
);
export type CreateConsignmentItemImageSchema = z.infer<
  typeof createConsignmentItemImageSchema
>;
export const selectConsignmentItemImageSchema = createSelectSchema(
  DBConsignmentItemImage
);
export type ConsignmentItemImageSchema = z.infer<
  typeof selectConsignmentItemImageSchema
>;

// Commissions
export const createCommissionCategorySchema =
  createInsertSchema(DBCommissionCategory);
export type CreateCommissionCategorySchema = z.infer<
  typeof createCommissionCategorySchema
>;
export const selectCommissionCategorySchema =
  createSelectSchema(DBCommissionCategory);
export type CommissionCategorySchema = z.infer<
  typeof selectCommissionCategorySchema
>;
export const updateCommissionCategorySchema = z.object({
  name: z.string().optional(),
  percent: z.string().optional(),
});
export type UpdateCommissionCategorySchema = z.infer<
  typeof updateCommissionCategorySchema
>;

// Charity
export const insertCharityRequestSchema = createInsertSchema(DBCharityRequest);
export type InsertCharityRequestSchema = z.infer<
  typeof insertCharityRequestSchema
>;
export const selectCharityRequestSchema = createSelectSchema(
  DBCharityRequest
).merge(
  z.object({
    items: z.array(z.string()),
  })
);
export type CharityRequestSchema = z.infer<typeof selectCharityRequestSchema>;

export const createCharityRequestSchema = z.object({
  charitySlug: z.string(),
  donationPercent: z.nativeEnum(CharityRequestPercents),
  paymentType: z.nativeEnum(CharityRequestPaymentType),
  name: z.string(),
  email: z.string().email(),
  phone: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  state: z.string().length(2, {
    message: "State must be a two-character code. I.e. TX for Texas",
  }),
  zip: z.string(),
  country: z.string().length(2, {
    message:
      "Country must be a two-character ISO code. I.e. US for United States",
  }),
  items: z.array(z.string().min(1)).min(1),
});

export type CreateCharityRequestSchema = z.infer<
  typeof createCharityRequestSchema
>;

export const updateCharityRequestSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(CharityRequestStatus).optional(),
  name: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  state: z
    .string()
    .length(2, {
      message: "State must be a two-character code. I.e. TX for Texas",
    })
    .optional(),
  zip: z.string().optional(),
  country: z
    .string()
    .length(2, {
      message:
        "Country must be a two-character ISO code. I.e. US for United States",
    })
    .optional(),
  donationPercent: z.nativeEnum(CharityRequestPercents).optional(),
  paymentType: z.nativeEnum(CharityRequestPaymentType).optional(),
});
export type UpdateCharityRequestSchema = z.infer<
  typeof updateCharityRequestSchema
>;

// Charity Image

export const insertCharityRequestImageSchema = createInsertSchema(
  DBCharityRequestImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type InsertCharityRequestImageSchema = z.infer<
  typeof insertCharityRequestImageSchema
>;
export const createCharityRequestImageSchema = z.object({
  charityRequestId: z.string().cuid2(),
  contentType: z.string(),
  contentLength: z.number(),
});
export type CreateCharityRequestImageSchema = z.infer<
  typeof createCharityRequestImageSchema
>;
export const updateCharityRequestImageSchema = z.object({
  id: z.string(),
  order: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  meta: z.any().optional(),
  featured: z.boolean().optional(),
  url: z.string().optional(),
  charityRequestId: z.string().optional(),
  processed: z.boolean().optional(),
});
export type UpdateCharityRequestImageSchema = z.infer<
  typeof updateCharityRequestImageSchema
>;
export const selectCharityRequestImageSchema = createSelectSchema(
  DBCharityRequestImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type CharityRequestImageSchema = z.infer<
  typeof selectCharityRequestImageSchema
>;

export const createCharityRequestItemSchema = z.object({
  charityRequestId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type CreateCharityRequestItemSchema = z.infer<
  typeof createCharityRequestItemSchema
>;

export const insertCharityRequestItemSchema =
  createInsertSchema(DBCharityRequestItem);
export type InsertCharityRequestItemSchema = z.infer<
  typeof insertCharityRequestItemSchema
>;
export const selectCharityRequestItemSchema =
  createSelectSchema(DBCharityRequestItem);
export type CharityRequestItemSchema = z.infer<
  typeof selectCharityRequestItemSchema
>;
export const updateCharityRequestItemSchema = z.object({
  id: z.string(),
  lotNumber: z.string().optional(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type UpdateCharityRequestItemSchema = z.infer<
  typeof updateCharityRequestItemSchema
>;

export const upsertCharityRequestItemSchema = z.object({
  charityRequestId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type UpsertCharityRequestItemSchema = z.infer<
  typeof upsertCharityRequestItemSchema
>;

export const createCharityRequestItemImageSchema = createInsertSchema(
  DBCharityRequestItemImage
);
export type CreateCharityRequestItemImageSchema = z.infer<
  typeof createCharityRequestItemImageSchema
>;
export const selectCharityRequestItemImageSchema = createSelectSchema(
  DBCharityRequestItemImage
);
export type CharityRequestItemImageSchema = z.infer<
  typeof selectCharityRequestItemImageSchema
>;

// Goldslayer
export const insertGoldslayerLeadSchema = createInsertSchema(DBGoldslayerLead);
export type InsertGoldslayerLeadSchema = z.infer<
  typeof insertGoldslayerLeadSchema
>;
export const selectGoldslayerLeadSchema = createSelectSchema(
  DBGoldslayerLead
).merge(
  z.object({
    items: z.array(z.string()),
  })
);
export type GoldslayerLeadSchema = z.infer<typeof selectGoldslayerLeadSchema>;

export const createGoldslayerLeadSchema = z.object({
  paymentType: z.nativeEnum(GoldslayerLeadPaymentType),
  name: z.string(),
  email: z.string().email(),
  phone: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  state: z.string().length(2, {
    message: "State must be a two-character code. I.e. TX for Texas",
  }),
  zip: z.string(),
  country: z.string().length(2, {
    message:
      "Country must be a two-character ISO code. I.e. US for United States",
  }),
  items: z.array(z.string().min(1)).min(1),
});

export type CreateGoldslayerLeadSchema = z.infer<
  typeof createGoldslayerLeadSchema
>;

export const updateGoldslayerLeadSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(GoldslayerLeadStatus).optional(),
  name: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().nullable().optional(),
  city: z.string().optional(),
  state: z
    .string()
    .length(2, {
      message: "State must be a two-character code. I.e. TX for Texas",
    })
    .optional(),
  zip: z.string().optional(),
  country: z
    .string()
    .length(2, {
      message:
        "Country must be a two-character ISO code. I.e. US for United States",
    })
    .optional(),
  paymentType: z.nativeEnum(GoldslayerLeadPaymentType).optional(),
});
export type UpdateGoldslayerLeadSchema = z.infer<
  typeof updateGoldslayerLeadSchema
>;

// Goldslayer Image

export const insertGoldslayerLeadImageSchema = createInsertSchema(
  DBGoldslayerLeadImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type InsertGoldslayerLeadImageSchema = z.infer<
  typeof insertGoldslayerLeadImageSchema
>;
export const createGoldslayerLeadImageSchema = z.object({
  goldslayerLeadId: z.string().cuid2(),
  contentType: z.string(),
  contentLength: z.number(),
});
export type CreateGoldslayerLeadImageSchema = z.infer<
  typeof createGoldslayerLeadImageSchema
>;
export const updateGoldslayerLeadImageSchema = z.object({
  id: z.string(),
  order: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  meta: z.any().optional(),
  featured: z.boolean().optional(),
  url: z.string().optional(),
  goldslayerLeadId: z.string().optional(),
  processed: z.boolean().optional(),
});
export type UpdateGoldslayerLeadImageSchema = z.infer<
  typeof updateGoldslayerLeadImageSchema
>;
export const selectGoldslayerLeadImageSchema = createSelectSchema(
  DBGoldslayerLeadImage
).merge(
  z.object({
    meta: z.any(),
  })
);
export type GoldslayerLeadImageSchema = z.infer<
  typeof selectGoldslayerLeadImageSchema
>;

export const createGoldslayerLeadItemSchema = z.object({
  goldslayerLeadId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type CreateGoldslayerLeadItemSchema = z.infer<
  typeof createGoldslayerLeadItemSchema
>;

export const insertGoldslayerLeadItemSchema =
  createInsertSchema(DBGoldslayerLeadItem);
export type InsertGoldslayerLeadItemSchema = z.infer<
  typeof insertGoldslayerLeadItemSchema
>;
export const selectGoldslayerLeadItemSchema =
  createSelectSchema(DBGoldslayerLeadItem);
export type GoldslayerLeadItemSchema = z.infer<
  typeof selectGoldslayerLeadItemSchema
>;
export const updateGoldslayerLeadItemSchema = z.object({
  id: z.string(),
  lotNumber: z.string().optional(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type UpdateGoldslayerLeadItemSchema = z.infer<
  typeof updateGoldslayerLeadItemSchema
>;

export const upsertGoldslayerLeadItemSchema = z.object({
  goldslayerLeadId: z.string().cuid2(),
  lotNumber: z.string(),
  title: z.string().optional(),
  hammerPrice: z.number().optional(),
  payout: z.number().optional(),
  auctionLink: z.string().optional(),
});
export type UpsertGoldslayerLeadItemSchema = z.infer<
  typeof upsertGoldslayerLeadItemSchema
>;

export const createGoldslayerLeadItemImageSchema = createInsertSchema(
  DBGoldslayerLeadItemImage
);
export type CreateGoldslayerLeadItemImageSchema = z.infer<
  typeof createGoldslayerLeadItemImageSchema
>;
export const selectGoldslayerLeadItemImageSchema = createSelectSchema(
  DBGoldslayerLeadItemImage
);
export type GoldslayerLeadItemImageSchema = z.infer<
  typeof selectGoldslayerLeadItemImageSchema
>;

// File
export const insertFileSchema = createInsertSchema(DBFile);
export type InsertFileSchema = z.infer<typeof insertFileSchema>;
export const selectFileSchema = createSelectSchema(DBFile);
export type FileSchema = z.infer<typeof selectFileSchema>;

export const createFileSchema = z.object({
  fileableId: z.string(),
  fileableType: z.nativeEnum(FileableType),
  contentType: z.string(),
  contentSize: z.number(),
  filename: z.string(),
  meta: z.any().optional(),
});
export type CreateFileSchema = z.infer<typeof createFileSchema>;
export const updateFileSchema = z.object({
  id: z.string(),
  meta: z.any().optional(),
});
export type UpdateFileSchema = z.infer<typeof updateFileSchema>;
