export const SIDEBAR_KEY = "sidebar.open";
export const getIsMenuOpen = () => {
  return (
    (typeof window !== "undefined" &&
      localStorage.getItem(SIDEBAR_KEY) === "true") ||
    true
  );
};

export const setIsMenuOpen = (isOpen: boolean) => {
  if (typeof window === "undefined") {
    return;
  }
  localStorage.setItem(SIDEBAR_KEY, isOpen.toString());
};
