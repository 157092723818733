import type { ClassValue } from "clsx";
import type { ReadonlyURLSearchParams } from "next/navigation";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export { clsx };

export function toArray<T>(
  value: T | T[] | undefined,
  as?: "number" | "string"
): T[] | undefined {
  if (!value) {
    return undefined;
  }

  if (as === "number") {
    return (Array.isArray(value) ? value : [value])
      .filter(Boolean)
      .map(Number) as T[];
  }

  if (as === "string") {
    return (Array.isArray(value) ? value : [value])
      .filter(Boolean)
      .map(String) as T[];
  }

  return (Array.isArray(value) ? value : [value]).filter(Boolean);
}

export const getValue = <T>(
  searchParams: URLSearchParams,
  key: string,
  options?: {
    prefix?: string;
    defaultValue?: T;
  }
): T => {
  const value = options?.prefix
    ? searchParams.get(`${options?.prefix}.${key}`)
    : searchParams.get(key);
  if (value) {
    // if T is a number, parse it
    if (!Array.isArray(value) && typeof options?.defaultValue === "number") {
      return Number(Number.parseInt(value)) as T;
    }
    return value as T;
  }
  return options?.defaultValue as T;
};

export const getAllValues = <T>(
  searchParams: URLSearchParams,
  key: string,
  options?: {
    prefix?: string;
    as?: "number" | "string";
    defaultValue?: T[];
    addValues?: T[];
  }
): T[] => {
  const value = options?.prefix
    ? searchParams.getAll(`${options?.prefix}.${key}`)
    : searchParams.getAll(key);
  if (value) {
    return [
      ...(toArray(value, options?.as) ?? []),
      ...(options?.addValues ?? []),
    ] as T[];
  }
  return options?.defaultValue ?? [];
};

export const setValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string,
  options?: {
    prefix?: string;
  }
): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  params.set(options?.prefix ? `${options?.prefix}.${key}` : key, value);

  return params;
};

export const appendValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string,
  options?: {
    prefix?: string;
  }
): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  const existingValues = params.getAll(key);
  if (!existingValues.includes(value)) {
    params.append(options?.prefix ? `${options?.prefix}.${key}` : key, value);
  }

  return params;
};

export const removeValue = (
  searchParams: URLSearchParams,
  key: string,
  value?: string,
  options?: {
    prefix?: string;
  }
): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  const newValues = getAllValues<string>(searchParams, key, {
    prefix: options?.prefix,
  });

  if (newValues.length === 0) {
    params.delete(options?.prefix ? `${options?.prefix}.${key}` : key);
  } else {
    const newParams = new URLSearchParams();

    for (const v of newValues) {
      if (v !== value) {
        newParams.append(
          options?.prefix ? `${options?.prefix}.${key}` : key,
          v as string
        );
      }
    }

    return newParams;
  }

  return params;
};

export const clearValue = (
  searchParams: URLSearchParams,
  key: string,
  options?: {
    prefix?: string;
  }
): URLSearchParams => {
  const params = new URLSearchParams(searchParams);

  params.delete(options?.prefix ? `${options?.prefix}.${key}` : key);

  return params;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const createUrl = (
  pathname: string,
  params: URLSearchParams | ReadonlyURLSearchParams
) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith)
    ? stringToCheck
    : `${startsWith}${stringToCheck}`;

export const generatePagination = (currentPage: number, totalPages: number) => {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, "...", totalPages - 1, totalPages];
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [
    1,
    "...",
    currentPage - 1,
    currentPage,
    currentPage + 1,
    "...",
    totalPages,
  ];
};

export const priceBucketDisplay = (priceBucket: number | string) => {
  // 0-9.99
  // 10-49.99
  // 50-99.99
  // 100-499.99
  // 500-999.99
  // 1000-4999.99
  // 5000-9999.99
  // 10000-49999.99
  // 50000-99999.99
  // 100000+

  const value =
    typeof priceBucket === "string"
      ? Number.parseInt(priceBucket)
      : priceBucket;

  if (value < 10) {
    return "< $10";
  }

  if (value === 10) {
    return "$10 - $50";
  }

  if (value === 50) {
    return "$50 - $100";
  }

  if (value === 100) {
    return "$100 - $500";
  }

  if (value === 500) {
    return "$500 - $1k";
  }

  if (value === 1000) {
    return "$1k - $5k";
  }

  if (value === 5000) {
    return "$5k - $10k";
  }

  if (value === 10000) {
    return "$10k - $50k";
  }

  if (value === 50000) {
    return "$50k - $100k";
  }

  if (value === 100000) {
    return "$100k - $500k";
  }

  return "Unknown";
};
